import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

const logoStyle = {
  width: '140px',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.white" mt={4}>
      © {new Date().getFullYear()} Cleanatic Services. All Rights Reserved.
    </Typography>
  );
}

export default function Footer() {

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        pt: { xs: 8, sm: 10 },
        pb: 2,
        // textAlign: { sm: 'center', md: 'left' },
        color: 'white',
        bgcolor: '#3F4041',
      }}
    >
      <Box
        maxWidth={1200}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '40%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box>
              <Typography variant="h5" color={'white'} fontWeight={'bold'} lineHeight={1} marginBottom={2}>
                CONNECT
                <br />
                WITH <span style={{ color: '#26B091' }}>US!</span>
              </Typography>
            </Box>
            <Typography variant="body2" color={'white'}>
              <a href="mailto:info@cleanaticservices.com" target='_blank' rel="noreferrer" style={{ color: 'white' }}>info@cleanaticservices.com</a>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
            mt: { sm: 0, xs: 2 }
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            GREECE
          </Typography>
          <Box sx={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2
          }}>
            <img
              src={'./assets/mark.svg'}
              // style={logoStyle}
              alt="mark"
            />
            <Typography variant="body2" sx={{
              mt: { sm: 0, xs: 2 }
            }}>
              ΝΑΙΑΔΩΝ 92 12137 ΑΘΗΝΑ
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <img
              src={'./assets/phone.svg'}
              // style={logoStyle}
              alt="phone"
            />
            <Typography variant="body2" sx={{
              mt: { sm: 0, xs: 2 }
            }}>
              698 940 7568
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
            mt: { sm: 0, xs: 2 }
          }}
        >
          <Typography variant="body2" fontWeight={600} >
            LEBANON
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <img
              src={'./assets/mark.svg'}
              // style={logoStyle}
              alt="mark"
            />
            <Typography variant="body2" sx={{
              mt: { sm: 0, xs: 2 }
            }}>
              Jounieh, lebanon karam building, 3rd floor
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <img
              src={'./assets/phone.svg'}
              // style={logoStyle}
              alt="phone"
            />
            <Typography variant="body2" sx={{
              mt: { sm: 0, xs: 2 }
            }}>
              +961 70 203 000
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        maxWidth={1200}
        sx={{
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'white',
        }}
      >
        <Box sx={{ md: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, sm: {} }}>
          <img
            src={'./logo/logo.svg'}
            style={logoStyle}
            alt="logo of sitemark"
          />
          <MenuItem
            onClick={() => scrollToSection('who-we-are')}
          >
            <Typography variant="body1" color="text.white">
              WHO WE ARE
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={() => scrollToSection('highlights')}
          >
            <Typography variant="body2" color="text.white">
              WHAT WE DO
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={() => scrollToSection('our-services')}
          >
            <Typography variant="body2" color="text.white">
              OUR SERVICES
            </Typography>
          </MenuItem>
        </Box>

        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
}