import React from 'react';
//rotas
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//pages
import Home from './Home';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='*' element={<Home />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;