import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#3F4041',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Typography component="h1" variant="h3" sx={{ fontWeight: 'bold', lineHeight: 0.9, letterSpacing: 0.5 }}>
              WHAT
              <br />
              WE DO
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography variant="body1" sx={{ color: 'grey.400', lineHeight: 1.2 }}>
              At <span style={{ color: '#26B091', fontWeight: 'bold' }}>Cleanatic Services</span>, we specialize in providing comprehensive cleaning solutions
              tailored to meet the diverse needs of our clients. From governmental hospitals to
              residential homes to corporate offices, and every space in between, we are committed
              to maintaining cleanliness and hygiene standards of the highest caliber.
              <br />
              <br />
              Our services extend beyond cleaning; we offer complete facility management solutions to households and businesses alike.
              Whether it's ensuring the smooth operation of your facilities, managing maintenance tasks, or handling day-to-day operations,
              <span style={{ color: '#26B091', fontWeight: 'bold' }}>Cleanatic Services</span> is your trusted partner for all your facility management needs.
              <br />
              <br />
              Our dedicated team works tirelessly to deliver exceptional results, ensuring that every space we clean is not just spotless
              but also a healthy and inviting environment.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}