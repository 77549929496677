import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function Features() {
  return (
    <Container id="who-we-are" sx={{ py: { xs: 4, sm: 8 }, textAlign: 'center' }}>
      <Typography component="h1" variant="h2" color="text.primary" fontWeight={'bold'} letterSpacing={3}>
        WHO WE ARE
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: { xs: 2, sm: 4 }, lineHeight: 1.3 }}
      >
        At <span style={{ color: '#26B091', fontWeight: 'bold' }}>Cleanatic Services</span>, a leading provider of cleaning services and facility management, we take immense pride in our commitment to delivering top-notch cleaning services and fostering strong relationships with our valued clientele.
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%' }}>
            <img
              src={'./assets/hwa.png'}
              style={{width: '100%'}}
              alt="who we are"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: { xs: 2, sm: 4 }, textAlign: 'justify', lineHeight: 1.3 }}
          >
            Our dedicated team at <b>Cleanatic</b> recognizes the significance of upholding clean and welcoming environments.
            Each member undergoes training and receives ongoing support to ensure unparalleled service quality.
            <br />
            <br />
            With over <b>10 years</b> of experience in the cleaning industry, <b>Cleanatic Services</b>
            has established itself as a trusted name in Greece, with roots extending from Lebanon.
            Our dedication to professionalism resonates throughout our company, reflected in the expertise and professionalism
            of our skilled cleaning staff.
            <br />
            <br />
            We are committed to surpassing the expectations of our clients by offering professional
            and superior cleaning solutions tailored to their unique needs. At <b>Cleanatic Services</b>,
            excellence is not just a goal; it's our standard.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}