import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const services = [
  {
    image: './assets/s1.png',
    title: 'City Cleaning Services'
  },
  {
    image: './assets/s2.png',
    title: 'Events Cleaning'
  },
  {
    image: './assets/s3.png',
    title: 'Commercial Cleaning'
  },
  {
    image: './assets/s4.png',
    title: 'Domestic Cleaning'
  },
  {
    image: './assets/s5.png',
    title: 'Deep Cleaning'
  },
  {
    image: './assets/s6.png',
    title: 'Hourly cleaning'
  },
  {
    image: './assets/s7.png',
    title: 'Monthly packages'
  },
];

export default function OurServices() {

  return (
    <Container
      id="our-services"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary" fontWeight={'bold'}>
          OUR SERVICES
        </Typography>
        <Typography variant="body1" color="text.secondary" lineHeight={1.2}>
          We offer a diverse array of services, from domestic to commercial cleaning, and everything in between,
          all tailored to meet your specific needs.
          <br />
          <br />
          Our services include the following:
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{justifyContent: 'center'}}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}

            >
                <img
                  src={service.image}
                  style={{ width: '100%' }}
                  alt={service.image}
                />

                <Typography component="h6" variant="h6" color="text.primary" textAlign={'center'}>
                  {service.title}
                </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}