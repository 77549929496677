import * as React from 'react';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import WhoWeAre from './components/WhoWeAre';
import OurServices from './components/OurServices';
import Footer from './components/Footer';

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        <WhoWeAre />
        <Divider />

        <Highlights />
        <Box sx={{ width: '80%', m: 'auto', mt: -5 }}>
          <img
            src={'./assets/wwd.png'}
            style={{ width: '100%' }}
            alt="who we are"
          />
        </Box>

        <OurServices />
        <Divider />
      </Box>
      <Footer />
    </ThemeProvider>
  );
}