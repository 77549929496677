import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage: `url(./assets/top.svg)`,
        // backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        minHeight: '90vh',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // justifyContent: 'center',
          pt: { xs: 45, sm: 45 },
          // pb: { xs: 8, sm: 0 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' }, textAlign: 'center' }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: '1.2rem',
              color: 'white',
              fontWeight: 'normal',
              letterSpacing: 5,
            }}
          >
            {`Excellence is not just a goal,`.toUpperCase()}
          </Typography>
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: '3.5rem',
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: 2,
              mt: -2,
            }}
          >
            {`It’s our standard`.toUpperCase()}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}